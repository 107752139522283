'use client'

import type { BuilderContent } from '@builder.io/sdk';
import { builder } from '@builder.io/sdk';
import { RefObject, memo, useEffect, useRef, useState } from "react";
import { RenderBuilderSection } from '../../builder/builder-section';

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

type HeaderContent = {
  banner: {
    name: string,
    class: string,
    content: BuilderContent
  }, header: {
    name: string,
    class: string,
    content: BuilderContent
  }
}

export default function HeaderDisplay({ headerContent }: { headerContent: HeaderContent }) {
  const [sticky, setSticky] = useState("");
  const [minHeight, setMinHeight] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  // on render, set listener
  useEffect(() => {
    setMinHeight(headerRef?.current?.clientHeight || 0);
    isSticky();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const bannerHeight = bannerRef?.current?.clientHeight || 0;
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= bannerHeight ? "header-container-sticky" : "";
    setSticky(stickyClass);
  };

  const headerStyle = {
    minHeight: `${minHeight}px`,
  }

  return (
    <header
      className={`header-container ${sticky}`}
      style={headerStyle}
    >
      <BannerSubcontainerDisplay banner={headerContent.banner} bannerRef={bannerRef} /> 
      <HeaderSubcontainerDisplay header={headerContent.header} headerRef={headerRef} /> 
      </header>
  );
}

const bannerSubcontainerDisplay = (props: {banner: HeaderContent['banner'], bannerRef?: RefObject<HTMLDivElement>}) => {
  return (
    <div
      className={`header-subcontainer ${props.banner.class}`}
      ref={props.bannerRef}>
      <RenderBuilderSection content={props.banner.content} model={props.banner.name} />
    </div>
  )
};
const BannerSubcontainerDisplay = memo(bannerSubcontainerDisplay);


const headerSubcontainerDisplay = (props: {header: HeaderContent['header'], headerRef?: RefObject<HTMLDivElement>}) => {
  return (
    <div
      className={`header-subcontainer ${props.header.class}`}
      ref={props.headerRef}>
      <RenderBuilderSection content={props.header.content} model={props.header.name} />
    </div>
  )
};
const HeaderSubcontainerDisplay = memo(headerSubcontainerDisplay);
