'use client';
'use client';
import { tag, user } from '@blotoutio/edgetag-sdk-js';
import { useEffect } from 'react';

export default function KlaviyoBlotoutHook () {
    useEffect(() => {
        const attachBlotoutEvent = (_e: Event) => {
            const email = document.querySelector<HTMLInputElement>('.klaviyo-form input[type="email"]')?.value;
            if(email){
                user('email', email);
                tag('Lead', { name: 'Footer Signup' });
            }
        }
        const timeout = setTimeout(() => {
            const klaviyoFormButton = document.querySelector('.klaviyo-form button');
            klaviyoFormButton?.addEventListener('click', attachBlotoutEvent);
        }, 2000);
        return () => {
            const klaviyoForm = document.querySelector('.klaviyo-form');
            klaviyoForm?.removeEventListener('click', attachBlotoutEvent);
            clearTimeout(timeout);
        }
    }, []);
	return null;
}
