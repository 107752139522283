'use client';

import { useCallback, useEffect, useRef } from "react";
import { SWRConfig } from "swr";

export function SWRConfigProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const swrCache = useRef(new Map());
  const swrLocalStorageProvider = useCallback(() => {
    if (typeof window !== "undefined") {
      try {
        const appCache = JSON.parse(localStorage.getItem("swr-cache") || "[]");
        swrCache.current = new Map<string, any>(appCache);
      } catch (error) {
        console.log(error);
      } finally {
        localStorage.removeItem("swr-cache");
      }
    }
    return swrCache.current;
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const saveSwrCache = (_event: BeforeUnloadEvent) => {
      const appCache = JSON.stringify(Array.from(swrCache.current.entries()));
      localStorage.setItem("swr-cache", appCache);
    }

    window.addEventListener('beforeunload', saveSwrCache)
    return () => {
      window.removeEventListener('beforeunload', saveSwrCache)
    }
  });
  swrLocalStorageProvider

  return (
    <SWRConfig value={{ provider: swrLocalStorageProvider }}>
      {children}
    </SWRConfig>
  )
}
