'use client';

import { BuilderComponent, BuilderContent, builder, useIsPreviewing } from '@builder.io/react';
import type { BuilderContent as BuilderContentType, GetContentOptions } from '@builder.io/sdk';
import { withSuspense } from 'components/with-suspense';
import HashStatic from 'object-hash';
import { renderLink } from './render-link';

import '../../builder-registry';

export interface BuilderSectionProps {
  content?: BuilderContentType;
  model?: string;
  data?: any;
  context?: any;
}

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

export function RenderBuilderSectionComponent({ content, model, data, context }: BuilderSectionProps) {
  const isPreviewing = useIsPreviewing();
  if (content || isPreviewing) {
    return <BuilderComponent
      content={content}
      model={model}
      data={{ config: { baseUrl: process.env.NEXT_PUBLIC_DOMAIN_NAME }, ...data }}
      context={context}
      renderLink={renderLink}
    />;
  }

  return null;
}

export function BuilderSectionComponent({ model, options, data, context }: {
  model: string;
  options?: GetContentOptions;
  data?: any;
  context?: any;
}) {
  return (
    <BuilderContent
      key={HashStatic(options?.userAttributes ?? {})}
      model={model}
      options={options}
    >
      {(_data, _loading, fullData) => {
        return (
          <RenderBuilderSection
            content={fullData}
            model={model}
            data={data}
            context={context}
          />
        );
      }}
    </BuilderContent>
  )
}

export const RenderBuilderSection = withSuspense(RenderBuilderSectionComponent, null);
